//=require jquery/dist/jquery.js
//=require jquery.cookie/jquery.cookie.js
//=require jquery-toast-plugin/dist/jquery.toast.min.js
//=require jquery.inputmask/dist/jquery.inputmask.bundle.js
//=require ./base/_autocomplete.js
//=require bootstrap/js/bootstrap.js
//=require swiper/swiper-bundle.js
//=require common.js

//=require ./base/_youtube-iframe-api.js
//=require ./base/_copyToClipboard.js


const $root = $(':root')
document.addEventListener('DOMContentLoaded', function () {
	
	//=require ./base/_resize.js
	//=require ./base/_getURLVar.js
	//=require ./base/_scrollSize.js
	//=require ./base/_viewportHeight.js
	//=require ./base/_mask.js
	//=require ./base/_textarea.js


	//=require ./extension/_wishlist.js
	//=require ./extension/_promo.js
	//=require ./extension/_split-item.js
	//=require ./extension/_show-password.js
	//=require ./extension/_show-more.js
	//=require ./extension/_form-errors.js
	//=require ./extension/_avatar.js
	//=require ./extension/_tabs.js
	//=require ./extension/_toggle.js

	//=require ./module/_modal.js
	//=require ./module/_slideshow.js
	//=require ./module/_stories-slider.js
	//=require ./module/_video-slider.js
	

	/*//=require ./common/_geo.js*/
	//=require ./common/_language.js
	//=require ./common/_search.js
	//=require ./common/_menu.js

	//=require ./product/_search.js

	//=require ./account/_wishlist.js
	//=require ./account/mpmultivendor/_promo.js


	$(window).trigger('resize').trigger('scroll')
})
